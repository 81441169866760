import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Container, Row, Col, Label, UncontrolledTooltip , InputGroup, Input, InputGroupText, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { HomeMap } from './components/MapView/HomeMap';
import HailHistory from './components/WeatherHistory/HailHistory';
import WindHistory from './components/WeatherHistory/WindHistory';
import ClimateScores from './components/ClimateScores/ClimateScores'
import DateFilter from './components/ComponentFilters/DateFilter';
import TabbedViewWeatherHistory from './components/WeatherHistory/TabbedViewWeatherHistory';
import SoilProfile from './components/SoilProfile/SoilProfile';
import Moment from 'moment';
import './App.css';
import { Calendar, CardImage, Exclude } from 'react-bootstrap-icons';

const Home = () => {
    var currentLat = 45;
    var currentLng = -90;

    const [selectedLocation, setSelectedLocation] = useState({ latitude: currentLat, longitude: currentLng });
    const [selectedReinsuranceYear, setSelectedReinsuranceYear] = useState(2022);
    const [filterDateRange, setFilterDateRange] = useState({ startDate: Moment('01/01/2023').utc(), endDate: Moment("12/31/2023").utc() })
    const [policyGrowerSearch, setPolicyGrowerSearch] = useState('');
    const [showSoilProfileModal, setShowSoilProfileModal] = useState(false);
    const [showDateFilterModal, setShowDateFilterModal] = useState(false);
    const [fieldGeometry, setFieldGeometry] = useState();
    const toggleSoilProfileModal = () => setShowSoilProfileModal(!showSoilProfileModal);
    const toggleDateFilterModal = () => setShowDateFilterModal(!showDateFilterModal);

    var newSearchTerm;

    return (

        <Container fluid>
            <SoilProfile title="Soil Profile" fieldGeometry={fieldGeometry} showSoilProfileModal={showSoilProfileModal} setShowSoilProfileModal={setShowSoilProfileModal} />
            <DateFilter filterDateRange={filterDateRange} setFilterDateRange={setFilterDateRange} showDateFilterModal={showDateFilterModal} setShowDateFilterModal={setShowDateFilterModal}></DateFilter>

            <Row className="row-margin-05">
                <Col className="col-md-5">
                    <ButtonToolbar>
                        <ButtonGroup>
                            <InputGroup>
                                <Input type="select" name="yearSelect" id="yearSelect" defaultValue={selectedReinsuranceYear}
                                    onChange={e => {
                                        setSelectedReinsuranceYear(Number(e.target.value))
                                    }}>
                                    <option>2020</option>
                                    <option>2021</option>
                                    <option>2022</option>
                                </Input>
                            </InputGroup>
                            <InputGroup>
                                <Input
                                    name='policyorgrowersearch'
                                    placeholder='Number or Name'
                                    value={newSearchTerm}
                                    type='search'
                                    onChange={e => {
                                        newSearchTerm = e.target.value;
                                    }} />
                                <InputGroupText>
                                    <Button
                                        onClick={e => {
                                            setPolicyGrowerSearch(newSearchTerm)
                                        }}
                                    >Search</Button>
                                </InputGroupText>
                            </InputGroup>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Col>
                <Col className="col-md-4" >
                    <ButtonToolbar>
                        <ButtonGroup>
                            <Button color="warning"  disabled={!fieldGeometry} onClick={toggleSoilProfileModal}><CardImage />   Soil Profile</Button>
                            <div><Button color="warning" id='droughtProfileButton' ><Exclude />   Drought Profile</Button>
                            <UncontrolledTooltip target="droughtProfileButton">
                                Coming Soon!
                            </UncontrolledTooltip ></div>
                            <div><Button color="warning" id='imageryAnalysisButton' ><Exclude />   Imagery Analysis</Button>
                            <UncontrolledTooltip target="imageryAnalysisButton">
                                Coming Soon!
                            </UncontrolledTooltip ></div>
                        </ButtonGroup>
                    </ButtonToolbar>

                </Col>
                <Col className="col-md-3" >
                    <ButtonToolbar>
                        <ButtonGroup>
                            <Button color="warning" onClick={toggleDateFilterModal}><Calendar />   Weather Filter: {Moment.utc(filterDateRange.startDate).format('MM/DD/YYYY')} - {Moment.utc(filterDateRange.endDate).format('MM/DD/YYYY')}</Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Col>
            </Row>

            <Row className="row-margin-05">
                <Col className="col-md-8">
                    <Row className="row-margin-05" >
                        <Col className="col-md-12  map-container" style={{ height: 520 }}>
                            <HomeMap
                                selectedLocation={selectedLocation}
                                setSelectedLocation={setSelectedLocation}
                                setFieldGeometry={setFieldGeometry}
                                policyGrowerSearch={policyGrowerSearch}
                                selectedReinsuranceYear={selectedReinsuranceYear}
                            >
                            </HomeMap>
                        </Col>
                    </Row>
                    <Row className="row-margin-05 " >
                        <Col className="col-md-12">
                            <ClimateScores title="Risk Scores" location={selectedLocation} ></ClimateScores>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-md-4">
                    <Row className="row">
                        <Col className="col-md-12">
                            <HailHistory title="Hail History" location={selectedLocation} filterDateRange={filterDateRange}></HailHistory>
                        </Col>
                    </Row>
                    <Row className="row-margin-05">
                        <Col className="col-md-12">
                            <WindHistory title="Wind History" location={selectedLocation} filterDateRange={filterDateRange}></WindHistory>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="row-margin-05">
                <Col className="col-md-12">
                    <TabbedViewWeatherHistory location={selectedLocation} filterDateRange={filterDateRange}></TabbedViewWeatherHistory>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;
